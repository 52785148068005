import React from 'react';
import styled from 'styled-components/macro';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Layout, { TEMPLATE } from '../components/Layout';

const Thanks = () => (
  <Layout title="Success" template={TEMPLATE.TEXT}>
    <h1>Thank you!</h1>
    <p>Your messaged was submitted successfully.</p>
    <p>
      <Link fade to="/contact">
        Send another message
      </Link>
      .
    </p>
  </Layout>
);

const Link = styled(AniLink)`
  color: ${({ theme }) => theme.color.white};
`;

export default Thanks;
